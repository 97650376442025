
<template>
  <div class="body add-point">
    <el-form
      :model="addForm"
      :rules="rules"
      :inline="true"
      ref="addForm"
      label-width="92px"
      label-position="left"
      class="demo-form-inline dialog-form"
    >
      <el-row :gutter="20">
        <el-col :span="8" :xs="8" :sm="8" :md="8">
          <!-- 选择车辆： -->
          <el-form-item label="选择车辆：" prop="vehId">
            <car-tree @getData="getVehIds" :choosedCph="choosedCph"></car-tree>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="班次：" prop="numberOfRun">
            <el-input v-model="addForm.numberOfRun"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" :xs="8" :sm="8" :md="8">
          <el-form-item label="出站日期：" prop="leaveDate">
            <el-date-picker
              class="marginR"
              v-model="addForm.leaveDate"
              :picker-options="pickerOptions"
              type="datetime"
              placeholder="选择日期时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8" :xs="8" :sm="8" :md="8">
          <el-form-item label="例检日期：" prop="inspectDate">
            <el-date-picker
              class="marginR"
              v-model="addForm.inspectDate"
              type="datetime"
              :picker-options="pickerOptions"
              placeholder="选择日期时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8" :xs="8" :sm="8" :md="8">
          <el-form-item label="天气情况：" prop="weatherCondition">
            <el-input v-model="addForm.weatherCondition"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" :xs="8" :sm="8" :md="8">
          <el-form-item label="驾驶员：" prop="driver">
            <el-input v-model="addForm.driver"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" :xs="8" :sm="8" :md="8">
          <el-form-item label="车站名称：" prop="busStopName">
            <el-input v-model="addForm.busStopName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" :xs="8" :sm="8" :md="8">
          <el-form-item label="装载人数：" prop="carryingLevel">
            <el-input-number
              v-model="addForm.carryingLevel"
              controls-position="right"
              :min="0"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8" :xs="8" :sm="8" :md="8">
          <el-form-item label="备注：">
            <el-input type="text" v-model="addForm.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="form-line"></div>
    <div class="form-check">
      <el-row :gutter="20">
        <el-col
          ::span="6"
          :xs="6"
          :sm="6"
          :md="6"
          v-for="(value, i) in modelList"
          :key="i"
        >
          <el-checkbox
            style="margin-bottom: 4px"
            :label="value.dictValue"
            name="type"
            v-model="safeForm[value.dictCode]"
          ></el-checkbox>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import carTree from '@/components/carTree/carSelectTree.vue'
import { formatDate, checkLimitDate } from '@/common/utils/index'
import {
  addOutbound,
  editOutbound,
  getOutboundById
} from '@/api/lib/gps-api.js'
import { queryDictsByCodes } from '@/api/lib/api.js'

export default {
  components: {
    carTree
  },
  props: {
    itemId: {
      type: [Number, String]
    },
    cph: {
      type: String
    }
  },
  data() {
    // 不能超前选择
    let validateDateForward = (rule, value, callback) => {
      if (value) {
        if (new Date() < value) {
          callback(new Error('不能选择未来时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      choosedCph: '',
      isEdit: false,
      safeForm: {},
      addForm: {
        vehId: null,
        busStopName: '',
        carryingLevel: null,
        driver: '',
        inspectDate: null,
        leaveDate: null,
        numberOfRun: null,
        remark: null,
        conditions: ''
      },
      pickerOptions: {
        disabledDate(time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        vehId: [{ required: true, message: '请选择车辆', trigger: 'change' }],
        leaveDate: [
          { required: true, message: '请选择出站日期', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'change' }
        ],
        numberOfRun: [
          { required: true, message: '请输入班次', trigger: 'blur' }
        ],
        inspectDate: [
          { required: true, message: '请选择例检日期', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'change' }
        ],
        weatherCondition: [
          { required: true, message: '请输入天气情况', trigger: 'blur' }
        ],
        carryingLevel: [
          { required: true, message: '请输入装载人数', trigger: 'blur' }
        ],
        driver: [{ required: true, message: '请输入驾驶员', trigger: 'blur' }],
        busStopName: [
          { required: true, message: '请输入车站名称', trigger: 'blur' }
        ],
        licenseplate: [
          { required: true, message: '请选择车辆', trigger: 'change' }
        ]
      },
      modelList: []
    }
  },
  methods: {
    // 获取车辆数据
    getVehIds(val) {
      this.$refs.addForm.clearValidate()
      this.addForm.vehId = val
    },
    // 新增/修改
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          const data = {
            ...this.addForm
          }
          data.leaveDate = formatDate(this.addForm.leaveDate)
          data.inspectDate = formatDate(this.addForm.inspectDate)
          data.conditions = JSON.stringify(this.safeForm)
          if (!this.isEdit) {
            addOutbound(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          } else {
            data.id = this.addForm.id
            editOutbound(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia(flag) {
      this.$emit('close', flag)
    },
    getDicts() {
      queryDictsByCodes({ parentCodes: 'WBCZ' }).then((res) => {
        if (res.code === 1000) {
          this.modelList = res.data.WBCZ
        }
      })
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    if (this.itemId) {
      getOutboundById({ id: this.itemId }).then((res) => {
        if (res.code === 1000) {
          this.addForm = { ...res.data }
          this.addForm.inspectDate = new Date(this.addForm.inspectDate)
          this.addForm.leaveDate = new Date(this.addForm.leaveDate)
          this.safeForm = JSON.parse(this.addForm.conditions)
          this.choosedCph = this.cph

          delete this.addForm.createTime
          delete this.addForm.updateTime
        } else {
          this.$message.error(res.msg)
        }
      })
      this.isEdit = true
    } else {
      this.isEdit = false
    }
  },
  watch: {
    cph(curVal, oldVal) {
      this.choosedCph = this.cph
    }
  }
}
</script>

<style lang="scss" scoped>
.add-point {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .form-line {
      border-top: 1px solid rgba(139, 151, 177, 0.4);
      margin-bottom: 20px;
    }
    .form-check {
      width: 85%;
      margin: 0 auto;
      margin-bottom: 20px;
      .el-checkbox + .el-checkbox {
        margin-left: 0;
      }
      .el-checkbox {
        margin-right: 50px;
      }
    }
  }
}
</style>
