var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-point" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "92px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8, xs: 8, sm: 8, md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择车辆：", prop: "vehId" } },
                    [
                      _c("car-tree", {
                        attrs: { choosedCph: _vm.choosedCph },
                        on: { getData: _vm.getVehIds }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "班次：", prop: "numberOfRun" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.numberOfRun,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "numberOfRun", $$v)
                          },
                          expression: "addForm.numberOfRun"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, xs: 8, sm: 8, md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "出站日期：", prop: "leaveDate" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "marginR",
                        attrs: {
                          "picker-options": _vm.pickerOptions,
                          type: "datetime",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.addForm.leaveDate,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "leaveDate", $$v)
                          },
                          expression: "addForm.leaveDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, xs: 8, sm: 8, md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "例检日期：", prop: "inspectDate" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "marginR",
                        attrs: {
                          type: "datetime",
                          "picker-options": _vm.pickerOptions,
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.addForm.inspectDate,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "inspectDate", $$v)
                          },
                          expression: "addForm.inspectDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, xs: 8, sm: 8, md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "天气情况：", prop: "weatherCondition" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.weatherCondition,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "weatherCondition", $$v)
                          },
                          expression: "addForm.weatherCondition"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, xs: 8, sm: 8, md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "驾驶员：", prop: "driver" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.driver,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "driver", $$v)
                          },
                          expression: "addForm.driver"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, xs: 8, sm: 8, md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车站名称：", prop: "busStopName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.busStopName,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "busStopName", $$v)
                          },
                          expression: "addForm.busStopName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, xs: 8, sm: 8, md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "装载人数：", prop: "carryingLevel" } },
                    [
                      _c("el-input-number", {
                        attrs: { "controls-position": "right", min: 0 },
                        model: {
                          value: _vm.addForm.carryingLevel,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "carryingLevel", $$v)
                          },
                          expression: "addForm.carryingLevel"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, xs: 8, sm: 8, md: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.addForm.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "remark", $$v)
                          },
                          expression: "addForm.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "form-line" }),
      _c(
        "div",
        { staticClass: "form-check" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.modelList, function(value, i) {
              return _c(
                "el-col",
                { key: i, attrs: { ":span": 6, xs: 6, sm: 6, md: 6 } },
                [
                  _c("el-checkbox", {
                    staticStyle: { "margin-bottom": "4px" },
                    attrs: { label: value.dictValue, name: "type" },
                    model: {
                      value: _vm.safeForm[value.dictCode],
                      callback: function($$v) {
                        _vm.$set(_vm.safeForm, value.dictCode, $$v)
                      },
                      expression: "safeForm[value.dictCode]"
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }